import {
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Graphic from "public/assets/snobase-rebrand.min.svg";
import { useEffect, useRef } from "react";
import { LocalStorageKeyBoolean } from "utils/localStorage";

export const LS_KEY = "isSnoBaseRebrandModalDismissed";

export default function RebrandModal() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const lsRef = useRef(new LocalStorageKeyBoolean(LS_KEY));

  useEffect(() => {
    const isDismissed = lsRef.current.get();
    if (!isDismissed) {
      onOpen();
    }
  }, [lsRef, onOpen]);

  const handleClose = () => {
    lsRef.current.set(true);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="cardBackground">
        <ModalHeader color="magenta.veryDark">
          Pitchable is Now SnoBase!
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            p: {
              fontSize: "lg",
              my: "1em",
              lineHeight: 1.25,
              color: "gray.900",
              strong: {
                fontWeight: 550,
              },
            },
          }}
        >
          <Box as={Graphic} width="100%" mb={6} />

          <Text>
            We are excited to announce the rebranding of Pitchable as{" "}
            <strong>SnoBase</strong>. Our new name reflects our goal of being
            your home base for sales and operations collaboration. To learn
            more, visit our new website at{" "}
            <Link
              href="https://www.snobase.com/"
              target="_blank"
              rel="noopener"
              fontWeight={500}
            >
              snobase.com
            </Link>
            .
          </Text>

          <Text>
            Nothing else has changed about the app you have been using apart
            from updates to our name and logo, as well as a few minor design
            adjustments. You can{" "}
            <strong>login here using your same Pitchable credentials</strong> to
            access your account.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
