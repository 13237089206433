import React from "react";
import { Box, Center, Container, Flex, Heading } from "@chakra-ui/react";
import Mountains from "public/assets/mountains.min.svg";
import { NAVBAR_HEIGHT } from "components/Layout/constants";
import Logo from "components/Layout/NavBar/Logo";
import { LANDING_PAGE_URL } from "utils/config";

type Props = {
  children: React.ReactNode;
  heading?: string;
  subheading?: string;
};

export default function LoginLayout({ children, heading, subheading }: Props) {
  return (
    <Flex minHeight="100vh" backgroundColor="lightBlueGrey" flexFlow="column">
      <Flex height={NAVBAR_HEIGHT}>
        <Logo href={LANDING_PAGE_URL} />
      </Flex>

      <Container as={Center} maxWidth="30rem" flexWrap="wrap" flex="1 1 100%">
        <Box width="100%">
          <Box as={Mountains} width="15rem" margin="0 auto" mb={8} />

          {heading && (
            <Heading
              as="h1"
              size="2xl"
              color="magenta.veryDark"
              lineHeight={1.25}
              textAlign="center"
            >
              {heading}
            </Heading>
          )}

          {subheading && (
            <Heading
              as="h2"
              size="lg"
              fontWeight={200}
              marginTop={4}
              textAlign="center"
            >
              {subheading}
            </Heading>
          )}

          {children}
        </Box>
      </Container>
    </Flex>
  );
}

export const getLayout = (page: React.ReactElement) => (
  <LoginLayout>{page}</LoginLayout>
);
